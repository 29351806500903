.Newsletter > p {
  margin-top: 30px;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 14px;
}
.Newsletter .NewsletterItem {
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid #949494;
  border-radius: 5px;
  margin: 18px 0;
}
.Newsletter .NewsletterItem .Details {
  flex: auto;
}
.Newsletter .NewsletterItem .Details .Title {
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
}
.Newsletter .NewsletterItem .Details .Description {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

