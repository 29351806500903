.Information {
  border-left: 1px solid #000;
  padding-left: 16px;
  margin: 32px 0;
}
.Information .I-Header {
  display: flex;
}
.Information .I-Header img {
  width: 17px;
  height: 17px;
  margin-right: 10px;
}
.Information .I-Header h4 {
  font-family: FiraSans-Italic;
  font-weight: 700;
  font-size: 14px;
}
.Information .details {
  font-family: FiraSans-Italic;
  font-weight: 400;
  font-size: 12px;
}

