
@import "../../assets/scss/variables";

.Accueil {
  display: flex;
  padding: 20px;
  height: 100%;
  justify-content: center;
  align-items: center;

  & .btn {
    width: 100%;
  }

  & .Container {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 76px;

    & .Logo {
      width: 152px;
      margin-bottom: 25px;
    }
    & .Titre {
      margin-bottom: 20px;
      font-weight: 900;
    }
    & .SousTitre {
      font-family: Merriweather-Italic;
      font-size: 18px;
      line-height: 23px
    }
  }
}
// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

  .Accueil {
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
    padding: 20px;
    margin: auto;

    .Container {
      margin: 30px 0 0;
    }
  }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .Accueil {
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;

  //
  //  max-width: 400px;
    .Container {
      //margin: 76px 20px;
    }
  }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {  }

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {  }

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {  }