@import '../../../assets/scss/variables';

$height: 40px;

.InputContainer {
  margin: 17px 0;
  position: relative;

  & .input-label {
    display: flex;
    flex-direction: column;
    position: relative;

    & label {
      margin-left: 15px;
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      position: absolute;
      top: -12px;
      background-color: #fff;
      display: inline-block;
      padding: 2px 4px;
    }

    & input {
      padding-left: 15px;
      padding-right: 44px;
      border-radius: 5px;
      border: 1px solid #000000;
      height: $height;
      &.is-invalid {
        border-color: $primary;
      }
      &::placeholder {
        font-family: FiraSans-Italic, serif;
        font-weight: 400;
        font-size: 14px;
        color: $color-placeholder;
      }
    }

    .badgeInduceFill {
      position: absolute;
      top:0;
      right: 0;
      padding: 1px;
      background-color: white;

      div {
        border-radius: 50%;
        background: #F22012;
        width: 8px;
        height: 8px;
      }
    }

    .viewerPassword {
      position: absolute;
      right: 0;
      top: 0;
      height: $height;
      display:flex;
      align-items:center;
    }

    & span img {
      margin: 15px;
      width: 14px;
    }

  }

  & .description p {
      font-weight: 400;
      font-size: 12px;
      margin-top: 5px;
  }

  .badgeInduceFill {
    display: none;
  }

  &.InduceFill {
    .input-label {
      label {
        color: $primary;
      }
      input {
        border-color: $primary;
      }
      .badgeInduceFill {
        display: block;
      }
    }
  }
}