.TextareaContainer {
  margin: 17px 0;
}
.TextareaContainer .Textarea-Label {
  display: flex;
  flex-direction: column;
  position: relative;
}
.TextareaContainer .Textarea-Label label {
  margin-left: 15px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  top: -12px;
  background-color: #fff;
  display: inline-block;
  padding: 2px 4px;
}
.TextareaContainer .Textarea-Label textarea {
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #000000;
}
.TextareaContainer .Textarea-Label textarea::placeholder {
  font-family: "FiraSans-Italic", serif;
  font-weight: 400;
  font-size: 14px;
  color: #D9D9D9;
}

