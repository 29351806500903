.SelectContainer {
  margin: 17px 0;
  position: relative;
}
.SelectContainer .label {
  display: flex;
  flex-direction: column;
  position: relative;
}
.SelectContainer .label label {
  margin-left: 15px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  top: -12px;
  background-color: #fff;
  display: inline-block;
  padding: 2px 4px;
}
.SelectContainer .label .chevron {
  top: 0;
  bottom: 0;
  right: 0;
  width: 12px;
  position: absolute;
  display: flex;
  justify-content: center;
  margin: 0 3px;
}
.SelectContainer .label .chevron img {
  width: 100%;
}
.SelectContainer .label select {
  padding-left: 15px;
  padding-right: 44px;
  border-radius: 5px;
  border: 1px solid #000000;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
}
.SelectContainer .label select option {
  font-family: "FiraSans-Italic", serif;
  font-weight: 400;
  font-size: 14px;
  color: #949494;
}
.SelectContainer .label .badgeInduceFill {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1px;
  background-color: white;
}
.SelectContainer .label .badgeInduceFill div {
  border-radius: 50%;
  background: #F22012;
  width: 8px;
  height: 8px;
}
.SelectContainer .description p {
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px;
}
.SelectContainer .badgeInduceFill {
  display: none;
}
.SelectContainer.InduceFill .label label {
  color: #F22012;
}
.SelectContainer.InduceFill .label select {
  border-color: #F22012;
}
.SelectContainer.InduceFill .label .badgeInduceFill {
  display: block;
}

