.RGPD {
  display: flex;
  flex-direction: column;
  align-items: center;
  .RGPD-Container {
    .RGPD-Dates {
      border: 1px solid #949494;
      border-radius: 5px;
      padding: 0 21px;

      .Debut, .Fin {
        padding: 10px;
        font-weight: 400;
        font-size: 12px;
        .Date {
          font-family: Merriweather-Regular;
          font-weight: 900;
          font-size: 14px;
        }
      }
      .Debut {
        border-bottom: 1px solid #949494;
      }
      .Fin {
      }
    }
    .Description {
      font-weight: 400;
      font-family: FiraSans-Italic;
      margin-top: 40px;
      font-size: 14px;
      text-align: center;
    }
  }
}

// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {  }

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {  }

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {  }

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {  }

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {  }