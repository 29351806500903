.Accueil {
  display: flex;
  padding: 20px;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.Accueil .btn {
  width: 100%;
}
.Accueil .Container {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 76px;
}
.Accueil .Container .Logo {
  width: 152px;
  margin-bottom: 25px;
}
.Accueil .Container .Titre {
  margin-bottom: 20px;
  font-weight: 900;
}
.Accueil .Container .SousTitre {
  font-family: Merriweather-Italic;
  font-size: 18px;
  line-height: 23px;
}

@media (max-width: 575.98px) {
  .Accueil {
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
    padding: 20px;
    margin: auto;
  }
  .Accueil .Container {
    margin: 30px 0 0;
  }
}
@media (max-width: 767.98px) {
  .Accueil {
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
  }
}

