.RGPD {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.RGPD .RGPD-Container .RGPD-Dates {
  border: 1px solid #949494;
  border-radius: 5px;
  padding: 0 21px;
}
.RGPD .RGPD-Container .RGPD-Dates .Debut, .RGPD .RGPD-Container .RGPD-Dates .Fin {
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
}
.RGPD .RGPD-Container .RGPD-Dates .Debut .Date, .RGPD .RGPD-Container .RGPD-Dates .Fin .Date {
  font-family: Merriweather-Regular;
  font-weight: 900;
  font-size: 14px;
}
.RGPD .RGPD-Container .RGPD-Dates .Debut {
  border-bottom: 1px solid #949494;
}
.RGPD .RGPD-Container .Description {
  font-weight: 400;
  font-family: FiraSans-Italic;
  margin-top: 40px;
  font-size: 14px;
  text-align: center;
}

