@import '../../../../assets/scss/variables';

.PhotoProfilBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 35px 0;

  .photoProfil {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 145px;
    height: 145px;
    border-radius: 50%;
    overflow: hidden;
    margin: 12px;

    & img {
      width: 100%;
    }
  }
  button {
    font-weight: 600;
    font-size: 16px;
    background: none;
    border: none;
  }
}