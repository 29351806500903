.carousel-inner {
  max-width: 710px;
  border-radius: 20px;
  overflow: hidden;
}
.carousel-inner .carousel-item {
  width: 100%;
}
.carousel-inner .carousel-item img {
  width: 100%;
}

.carousel-control-prev, .carousel-control-next {
  width: initial;
  opacity: 1;
}

.carousel-indicators {
  align-items: center;
}
.carousel-indicators [data-bs-target] {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.carousel-indicators .active {
  width: 16px;
  height: 16px;
}

@media (max-width: 575.98px) {
  .MyCarousel .carousel-inner {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .MyCarousel {
    max-width: 400px;
  }
}

