.MotdepasseOublier {
  & .indication {
    margin-top: 46px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  & .InputContainer:first-child {
    margin-top: 18px;
  }

  & .retour {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }
}
