.UpdatePassword {
  .Body {
    form {
      .zeorihgj {
        display: flex;
        align-items: flex-start;
        .zrijfedkos {
          width: 400px;
        }
        .Information {
          width: 243px;
          margin: 17px 0 17px 32px;
        }
      }
      .aldfjvhkc {
        width: 400px;
        text-align: center;
      }
    }
  }
}

// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {  }

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .UpdatePassword {
    //max-width: 400px;
    .Body form .zeorihgj {
      flex-direction: column;
      align-items: center;

      .zrijfedkos, .aldfjvhkc, .Information {
        width: initial;
      }
      .Information {
        margin-left: 0;
      }
    }
  }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {  }

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {  }

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {  }