
@import '../../assets/scss/fonts';

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";


// 2. Include any default variable overrides here
@import "../../assets/scss/variables";

// 3. Include remainder of required Bootstrap stylesheets
@import "../../../node_modules/bootstrap/scss/variables";

// 4. Include any default map overrides here
$form-feedback-invalid-color: $primary;
$disabled: #D4D4D4;
$modal-content-color: $body-color;
$modal-footer-bg: $white;
$FiraSansItalic: FiraSans-Italic, serif;

// 5. Include remainder of required parts
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../../node_modules/bootstrap/scss/utilities/api";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/carousel";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/close";
@import "../../../node_modules/bootstrap/scss/badge";

html, body, .App {
  height: 100vh;
  width: 100vw;
  font-size: 14px;
}

* {
  font-family: FiraSans-Regular, serif;
}

h1 {
  font-family: Merriweather-Black, serif;
}
h2, h3, h4, h5, h6 {
  font-family: Merriweather-Regular, serif;
}

p {
  margin: 0;
}

*:not(input, textarea) {
  user-select: none;
}

input:not([type=checkbox]) {
  font-family: Inter-Regular, serif;
  outline-color: $primary;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

select:focus-visible {
  outline-color: $primary;
}
select {
  appearance: none;
}

.btn {
  font-weight: 600;
  height: 45px;
  font-size: 18px;
  line-height: 22px;
  border-radius: 45px;
  margin: 16px 0;

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    color: white;
  }

  &:disabled, &.disabled {
    background-color: $disabled;
    border-color: $disabled;
  }
}

.btn-primary {
  &, &:hover, &:active {
    color: white;
  }
}
.btn-secondary {
  &, &:hover, &:active {
    color: white;
  }
}

.form-switch {
  .form-check-input {
    float: unset;
    width: 43px;
    height: 28px;
    margin-left: 0;

    &, &:focus, &:active {
      filter: brightness(100%);
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
    }

    &:not(:checked):focus {
      border-color: black;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
    }

    &:checked {
      background-color: $primary;
    }
    &:not(:checked) {
      background-color: black;
    }
  }
}

/** Alert */
.alert {
  font-size: 0.9rem;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  margin: 1rem 0;
  &.alert-dismissible .btn-close {
      padding: 0.75rem 0.5rem;
  }
}

/** Validation */
.was-validated {
  .valid-feedback, .invalid-feedback {
    font-family: $FiraSansItalic;
    font-weight: 400;
    font-size: 12px;
  }
}

.msg-alert {
  font-family: $FiraSansItalic;
  font-size: 12px;
  text-align: center;
  color: $primary;
  margin: 5px 0;
}