.UpdatePassword .Body form .zeorihgj {
  display: flex;
  align-items: flex-start;
}
.UpdatePassword .Body form .zeorihgj .zrijfedkos {
  width: 400px;
}
.UpdatePassword .Body form .zeorihgj .Information {
  width: 243px;
  margin: 17px 0 17px 32px;
}
.UpdatePassword .Body form .aldfjvhkc {
  width: 400px;
  text-align: center;
}

@media (max-width: 767.98px) {
  .UpdatePassword {
    /*max-width: 400px;*/
  }
  .UpdatePassword .Body form .zeorihgj {
    flex-direction: column;
    align-items: center;
  }
  .UpdatePassword .Body form .aldfjvhkc {
    width: initial;
  }
  .UpdatePassword .Body form .zeorihgj .zrijfedkos, .UpdatePassword .Body form .zeorihgj .aldfjvhkc, .UpdatePassword .Body form .zeorihgj .Information {
    width: initial;
  }
  .UpdatePassword .Body form .zeorihgj .Information {
    margin-left: 0;
  }
}

