.UserDelete label {
  font-size: 14px;
}

@media (max-width: 767.98px) {
  .UserDelete form button[type=submit] {
    display: block;
    margin: auto;
  }
}

