
@import "../../../assets/scss/variables";

.carousel-inner {
    max-width: 710px;
    border-radius: 20px;
    overflow: hidden;
    .carousel-item {
        width: 100%;
        img {
            width: 100%;
        }
    }
}
.carousel-control-prev, .carousel-control-next
{
    width: initial;
    opacity: 1;
}
.carousel-indicators {
    align-items: center;

    & [data-bs-target] {
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }
    & .active {
        width: 16px;
        height: 16px;
    }
}

// `xs` returns only a ruleset and no media query
// ... { ... }

// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .MyCarousel .carousel-inner {
        //max-height: 341px;
        //max-width: 320px;
        display: none;
    }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .MyCarousel {
        max-width: 400px;
    }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

}

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {

}