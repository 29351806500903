@import '../../../assets/scss/variables';

.SelectContainer {
  margin: 17px 0;
  position: relative;

  & .label {
    display: flex;
    flex-direction: column;
    position: relative;

    & label {
      margin-left: 15px;
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      position: absolute;
      top: -12px;
      background-color: #fff;
      display: inline-block;
      padding: 2px 4px;
    }

    .chevron {
      top: 0;
      bottom: 0;
      right: 0;
      width: 12px;
      position: absolute;
      display: flex;
      justify-content: center;
      margin: 0 3px;

      img {
        width: 100%;
      }
    }

    & select {
      padding-left: 15px;
      padding-right: 44px;
      border-radius: 5px;
      border: 1px solid #000000;
      height: 40px;
      font-weight: 400;
      font-size: 14px;

      & option {
        font-family: 'FiraSans-Italic', serif;
        font-weight: 400;
        font-size: 14px;
        color: #949494;
      }
    }

    .badgeInduceFill {
      position: absolute;
      top:0;
      right: 0;
      padding: 1px;
      background-color: white;

      div {
        border-radius: 50%;
        background: #F22012;
        width: 8px;
        height: 8px;
      }
    }
  }
  & .description p {
    font-weight: 400;
    font-size: 12px;
    margin-top: 5px;
  }
  .badgeInduceFill {
    display: none;
  }

  &.InduceFill {
    .label {
      label {
        color: $primary;
      }
      select {
        border-color: $primary;
      }
      .badgeInduceFill {
        display: block;
      }
    }
  }
}