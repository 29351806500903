@import '../../fonts/Icon/css/fontello.css';
/* Couleur par défaut car Bleu sur IOS (natif) */
select {
  color: black;
}
button {
  color: black;
}

#Dashboard {
  height: 100%;
  width: 100%;
  display: flex;
}
#Dashboard #Menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden auto;
  padding: 50px;
  width: 412px;
  height: 100%;
}
#Dashboard #Menu {
  background-image: url("../../assets/image/FondMenu.svg");
  background-size: cover;
  background-position: center;
  background-color: #fff;
}
#Dashboard #Menu > .Header {
  margin-bottom: 27px;
}
#Dashboard #Menu > .Header > .Logo img {
  height: 24px;
}
#Dashboard #Menu > .Header .Action {
  display: none;
}
#Dashboard #Menu .Body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#Dashboard #Menu .Body .Header .ProfilPicture {
  display: none;
}
#Dashboard #Menu .Body .Header h1 {
  margin-bottom: 60px;
  font-weight: 900;
  font-size: 41px;
}
#Dashboard #Menu .Body .MenuContent {
  height: 100%;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem {
  margin-bottom: 52px;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu {
  display: flex;
  align-items: start;
  cursor: pointer;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu.active {
  color: #F22012;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu > .Icon i, #Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu > .Text h3, #Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu > .Action i {
  line-height: 27px;
  vertical-align: middle;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu > .Text, #Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu > .Text h3 {
  white-space: nowrap;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu > .Action i:before {
  transition: 0.4s;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu.active > .Action i:before {
  transition: 0.4s;
  rotate: 90deg;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu > .Icon {
  padding-right: 16px;
  position: relative;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu > .Icon span.icon-badge {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #F22012;
  top: 0;
  border-radius: 50%;
  overflow: hidden;
  right: 14px;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu > .Text h3 {
  margin: 0;
  font-weight: 900;
  font-size: 18px;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu > .Action {
  margin-left: 10px;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu#menuProfil > .Icon span.icon-badge {
  top: 5px;
  right: 15px;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .MainMenu#menuSecurity > .Icon span.icon-badge {
  top: 4px;
  right: 19px;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .SubMenus {
  transition: 0.5s;
  overflow: hidden;
  cursor: pointer;
  height: 100px;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .SubMenus .active {
  color: #F22012;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .SubMenus.hide {
  height: 0;
}
#Dashboard #Menu .Body .MenuContent > .MenuItem .SubMenus > div {
  font-family: Merriweather-Regular, serif;
  vertical-align: middle;
  line-height: 20px;
  margin: 20px 0;
  margin-left: 32px;
  font-weight: 900;
  font-size: 16px;
  padding-left: 10px;
}
#Dashboard #Menu .Body .MenuContent .MenuItem #menuProfil .Icon {
  padding-right: 21px;
}
#Dashboard #Menu .Body .MenuContent .MenuItem #menuProfil .Icon span {
  right: 20px !important;
}
#Dashboard #Menu .Body .MenuContent .MenuItem #menuNewsletter .Icon {
  padding-right: 21px;
}
#Dashboard #Menu .Body .MenuContent .MenuItem #menuProfil .Icon span {
  right: 20px !important;
}

#Dashboard #Menu .Body .Deconnexion {
  font-weight: 400;
  font-size: 14px;
  border-top: 1px solid #000;
  padding: 10px 0;
  width: 100%;
}
#Dashboard #Menu .Body .Deconnexion img {
  margin-right: 7px;
}
#Dashboard #Menu .Body .Deconnexion a {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
#Dashboard .Content {
  margin: 0 auto;
  flex: auto;
  overflow: hidden auto;
  max-width: 710px;
  width: 100%;
}
#Dashboard .Content .Header {
  height: 115px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  border-bottom: 1px solid #949494;
}
#Dashboard .Content .Header * {
  margin: auto 0;
}
#Dashboard .Content .Header h2 {
  font-weight: 900;
  font-size: 25px;
}
#Dashboard .Content .Header .ProfilPicture {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}
#Dashboard .Content .Header .ProfilPicture img {
  width: 100%;
}
#Dashboard .Content .Body .Subtitle {
  margin: 30px 0;
}
#Dashboard .Content .Body .Subtitle h3 {
  font-weight: 900;
  font-size: 18px;
  margin-top: 10px;
}
#Dashboard .Content .Body .Subtitle p {
  font-weight: 400;
  font-size: 14px;
}

@media (max-width: 1199.98px) {
  #Dashboard {
    flex-direction: column;
    justify-content: start;
  }
  #Dashboard #Menu {
    width: 100%;
    z-index: 2;
    background: #fff none;
    padding: 0;
    height: initial;
  }
  #Dashboard #Menu > .Header {
    background-image: url("../../assets/image/FondMenu.svg");
    background-size: cover;
    background-position: center;
    background-color: #fff;
    width: 100%;
    padding: 0 20px;
    margin: 0;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }
  #Dashboard #Menu > .Header span {
    font-family: "Merriweather-Regular", serif;
    font-weight: 700;
    font-size: 14px;
  }
  #Dashboard #Menu > .Header .Logo img {
    height: 16px;
  }
  #Dashboard #Menu > .Header .Action {
    display: flex;
    flex-direction: row;
  }
  #Dashboard #Menu > .Header .Action img {
    margin-right: 4px;
  }
  #Dashboard #Menu .Body {
    display: none;
    height: 0;
    overflow: hidden auto;
  }
  #Dashboard #Menu.Open {
    position: absolute;
    height: 100%;
  }
  #Dashboard #Menu.Open .Body {
    display: flex;
    overflow: auto;
    z-index: 1;
    background: #fff;
    width: 100%;
    height: calc(100% - 50px);
    padding: 30px;
  }
  #Dashboard #Menu.Open .Body .Header {
    background: none;
    margin-bottom: 50px;
    padding: 0;
  }
  #Dashboard #Menu.Open .Body .Header h1 {
    font-weight: 900;
    font-size: 31px;
    margin-bottom: 0;
  }
  #Dashboard #Menu.Open .Body .Header .ProfilPicture {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
  }
  #Dashboard #Menu.Open .Body .Header .ProfilPicture img {
    width: 100%;
  }
  #Dashboard #Menu.Open .Body .MenuContent h3 {
    font-weight: 400;
    font-size: 18px;
  }
  #Dashboard #Menu.Open .Body .MenuContent .MainMenu.active h3 {
    font-weight: 900;
  }
  #Dashboard .Content {
    padding: 20px;
  }
  #Dashboard .Content .Header {
    height: unset;
  }
  #Dashboard .Content .Header .Title {
    margin: 10px 0;
  }
  #Dashboard .Content .Header .Title h2 {
    font-weight: 900;
    font-size: 18px;
  }
  #Dashboard .Content .Header .ProfilPicture {
    display: none;
  }
  #Dashboard .Content .Body .Subtitle {
    margin: 10px 0;
  }
  #Dashboard .Content .Body .Subtitle h3 {
    font-size: 16px;
    margin-top: 0;
  }
}

button.buttonPadding {
  padding: 11.5px 25px 11.5px 25px;
  height: auto;
}

@media (max-height: 768px) {
  #Dashboard #Menu .Body .MenuContent {
    height: auto;
  }
}

