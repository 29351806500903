/**
  Merriweather
 */
@font-face {
  font-family: Merriweather-Black;
  src: url(../../fonts/Merriweather-Black.ttf) format('truetype');
}
@font-face {
  font-family: Merriweather-BlackItalic;
  src: url(../../fonts/Merriweather-BlackItalic.ttf) format('truetype');
}
@font-face {
  font-family: Merriweather-Bold;
  src: url(../../fonts/Merriweather-Bold.ttf) format('truetype');
}
@font-face {
  font-family: Merriweather-BoldItalic;
  src: url(../../fonts/Merriweather-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: Merriweather-Italic;
  src: url(../../fonts/Merriweather-Italic.ttf) format('truetype');
}
@font-face {
  font-family: Merriweather-Light;
  src: url(../../fonts/Merriweather-Light.ttf) format('truetype');
}
@font-face {
  font-family: Merriweather-LightItalic;
  src: url(../../fonts/Merriweather-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: Merriweather-Regular;
  src: url(../../fonts/Merriweather-Regular.ttf) format('truetype');
}


/**
  FiraSans
 */
@font-face {
  font-family: FiraSans-Black;
  src: url(../../fonts/FiraSans-Black.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-BlackItalic;
  src: url(../../fonts/FiraSans-BlackItalic.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-Bold;
  src: url(../../fonts/FiraSans-Bold.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-BoldItalic;
  src: url(../../fonts/FiraSans-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-ExtraBold;
  src: url(../../fonts/FiraSans-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-ExtraBoldItalic;
  src: url(../../fonts/FiraSans-ExtraBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-ExtraLight;
  src: url(../../fonts/FiraSans-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-ExtraLightItalic;
  src: url(../../fonts/FiraSans-ExtraLightItalic.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-Italic;
  src: url(../../fonts/FiraSans-Italic.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-Light;
  src: url(../../fonts/FiraSans-Light.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-LightItalic;
  src: url(../../fonts/FiraSans-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-Medium;
  src: url(../../fonts/FiraSans-Medium.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-MediumItalic;
  src: url(../../fonts/FiraSans-MediumItalic.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-Regular;
  src: url(../../fonts/FiraSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-SemiBold;
  src: url(../../fonts/FiraSans-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-SemiBoldItalic;
  src: url(../../fonts/FiraSans-SemiBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-Thin;
  src: url(../../fonts/FiraSans-Thin.ttf) format('truetype');
}
@font-face {
  font-family: FiraSans-ThinItalic;
  src: url(../../fonts/FiraSans-ThinItalic.ttf) format('truetype');
}


/**
Inter
 */
@font-face {
  font-family: Inter-Black;
  src: url(../../fonts/Inter-Black.ttf) format('truetype');
}
@font-face {
  font-family: Inter-Bold;
  src: url(../../fonts/Inter-Bold.ttf) format('truetype');
}
@font-face {
  font-family: Inter-ExtraBold;
  src: url(../../fonts/Inter-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: Inter-ExtraLight;
  src: url(../../fonts/Inter-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: Inter-Light;
  src: url(../../fonts/Inter-Light.ttf) format('truetype');
}
@font-face {
  font-family: Inter-Medium;
  src: url(../../fonts/Inter-Medium.ttf) format('truetype');
}
@font-face {
  font-family: Inter-Regular;
  src: url(../../fonts/Inter-Regular.ttf) format('truetype');
}
@font-face {
  font-family: Inter-SemiBold;
  src: url(../../fonts/Inter-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: Inter-Thin;
  src: url(../../fonts/Inter-Thin.ttf) format('truetype');
}