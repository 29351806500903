.MySwitch {
  display: flex;
  align-items: center;
}
.MySwitch label {
  margin-right: 10px;
  font-weight: 400;
  font-size: 12px;
}
.MySwitch input {
  width: 43px;
  height: 28px;
}

