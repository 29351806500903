.NotificationItem {
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid #949494;
  border-radius: 5px;
  margin: 18px 0;
}
.NotificationItem .Details {
  flex: auto;
}
.NotificationItem .Details .Title {
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
}
.NotificationItem .Details .Description {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.NotificationItem a {
  font-weight: 600;
  font-size: 12px;
}

