.InputContainer {
  margin: 17px 0;
  position: relative;
}
.InputContainer .input-label {
  display: flex;
  flex-direction: column;
  position: relative;
}
.InputContainer .input-label label {
  margin-left: 15px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  top: -12px;
  background-color: #fff;
  display: inline-block;
  padding: 2px 4px;
}
.InputContainer .input-label input {
  padding-left: 15px;
  padding-right: 44px;
  border-radius: 5px;
  border: 1px solid #000000;
  height: 40px;
}
.InputContainer .input-label input.is-invalid {
  border-color: #F22012;
}
.InputContainer .input-label input::placeholder {
  font-family: FiraSans-Italic, serif;
  font-weight: 400;
  font-size: 14px;
  color: #D9D9D9;
}
.InputContainer .input-label .badgeInduceFill {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1px;
  background-color: white;
}
.InputContainer .input-label .badgeInduceFill div {
  border-radius: 50%;
  background: #F22012;
  width: 8px;
  height: 8px;
}
.InputContainer .input-label .viewerPassword {
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  display: flex;
  align-items: center;
}
.InputContainer .input-label span img {
  margin: 15px;
  width: 14px;
}
.InputContainer .description p {
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px;
}
.InputContainer .badgeInduceFill {
  display: none;
}
.InputContainer.InduceFill .input-label label {
  color: #F22012;
}
.InputContainer.InduceFill .input-label input {
  border-color: #F22012;
}
.InputContainer.InduceFill .input-label .badgeInduceFill {
  display: block;
}

