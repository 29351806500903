.MySwitch {
  display: flex;
  align-items: center;

  & label {
    margin-right: 10px;
    font-weight: 400;
    font-size: 12px;
  }
  & input {
    width: 43px;
    height: 28px;
  }
}