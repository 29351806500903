@import '../../fonts/Icon/css/fontello.css';
@import '../../assets/scss/variables';

#Dashboard {
  height: 100%;
  width: 100%;
  display: flex;

  #Menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden auto;
    padding: 50px;
    width: 412px;
    height: 100%;

    & {
      background-image: url('../../assets/image/FondMenu.svg');
      background-size: cover;
      background-position: center;
      background-color: #fff;
    }

    & > .Header {
      margin-bottom: 27px;
      & > .Logo img {
        height: 24px;
      }
      & .Action {
        display: none;
      }
    }
    .Body {
      height: 100%;
      display: flex;
      flex-direction: column;

      .Header {
        .ProfilPicture {
          display: none;
        }

        h1 {
          margin-bottom: 60px;
          font-weight: 900;
          font-size: 41px;
        }
      }

      .MenuContent {
        height: 100%;

        & > .MenuItem {
          margin-bottom: 52px;

          & .MainMenu {
            display: flex;
            align-items: start;
            cursor: pointer;

            &.active {
              color: $primary;
            }

            > .Icon i, > .Text h3, > .Action i {
              line-height: 27px;
              vertical-align: middle;
            }

            & > .Icon {
              padding-right: 16px;
              position: relative;

              span.icon-badge {
                position:absolute;
                width: 5px;
                height: 5px;
                background: $primary;
                top: 0;
                border-radius: 50%;
                overflow: hidden;
                right: 14px;
              }

            }
            & > .Text {
              h3 {
                margin: 0;
                font-weight: 900;
                font-size: 18px;
              }
            }
            & > .Action {
              margin-left: 10px;
            }
            &#menuProfil > .Icon span.icon-badge {
              top: 5px;
              right: 15px
            }
            &#menuSecurity > .Icon span.icon-badge {
              top: 4px;
              right: 19px;
            }
          }

          .SubMenus {
            //margin: 20px;
            transition: .5s;
            overflow: hidden;
            cursor: pointer;

            & .active {
              color: $primary;
            }
            &.hide {
              height: 0;
            }
            & > div {
              font-family: Merriweather-Regular, serif;
              vertical-align: middle;
              line-height: 20px;
              margin: 20px 0;
              margin-left: 32px;
              font-weight: 900;
              font-size: 16px;
              padding-left: 10px;
            }
          }
        }
      }
      .Deconnexion {
        font-weight: 400;
        font-size: 14px;
        border-top: 1px solid #000;
        padding: 10px 0;
        width: 100%;

        img {
          margin-right: 7px;
        }
        a {
          font-weight: 400;
          font-size: 14px;
          color: #000;
        }
      }
    }
  }

  .Content {
    margin: 0 auto;
    flex: auto;
    overflow: hidden auto;
    max-width: 710px;
    width: 100%;

    & .Header {
      height: 115px;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      border-bottom: 1px solid #949494;

      & * {
        margin: auto 0;
      }

      & h2 {
        font-weight: 900;
        font-size: 25px;
      }

      & .ProfilPicture {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        & img {
          width: 100%;
        }
      }
    }
    .Body {
      .Subtitle {
        margin: 30px 0;
        h3 {
          font-weight: 900;
          font-size: 18px;
          margin-top: 10px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
}

// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {  }

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  #Dashboard .Content {
    //max-width: 400px;
  }
}


// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  #Dashboard {
    flex-direction: column;
    justify-content: start;

    #Menu {
      width: 100%;
      z-index: 2;
      background: #fff none;
      padding: 0;
      height: initial;

      > .Header {
        background-image: url('../../assets/image/FondMenu.svg');
        background-size: cover;
        background-position: center;
        background-color: #fff;
        width: 100%;
        padding: 0 20px;
        margin: 0;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        .Logo {
        }

        span {
          font-family: "Merriweather-Regular", serif;
          font-weight: 700;
          font-size: 14px;
        }

        & .Logo img {
          height: 16px;
        }

        & .Action {
          display: flex;
          flex-direction: row;
          img {
            margin-right: 4px;
          }
        }
      }

      .Body {
        display: none;
        height: 0;
        overflow: hidden auto;;
      }

      &.Open {
        position: absolute;
        height: 100%;

        .Body {
          display: flex;
          overflow: auto;
          z-index: 1;
          background: #fff;
          width: 100%;
          height: calc(100% - 50px);
          padding: 30px;

          .Header {
            background: none;
            margin-bottom: 50px;
            padding: 0;

            h1 {
              font-weight: 900;
              font-size: 31px;
              margin-bottom: 0;
            }
            .ProfilPicture {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              overflow: hidden;
              img {
                width: 100%;
              }
            }

          }

          .MenuContent {
            h3 {
              font-weight: 400;
              font-size: 18px;
            }
            .MainMenu.active {
              h3 {
                font-weight: 900;
              }
            }
          }
        }
      }
    }
    .Content {
      padding: 20px;
      .Header {
        height: unset;
        .Title {
          margin: 10px 0;
          h2 {
            font-weight: 900;
            font-size: 18px;
          }
        }
        .ProfilPicture {
          display: none;
        }
      }
      .Body {
        .Subtitle {
          margin: 10px 0;
          h3 {
            font-size: 16px;
            margin-top: 0;
          }
      }
    }
  }
}
}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {  }