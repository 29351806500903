.Information {
  border-left: 1px solid #000;
  padding-left: 16px;
  margin: 32px 0;

  .I-Header {
    display: flex;

    img {
      width: 17px;
      height: 17px;
      margin-right: 10px
    }

    h4 {
      font-family: FiraSans-Italic;
      font-weight: 700;
      font-size: 14px;
    }
  }

  .details {
    font-family: FiraSans-Italic;
    font-weight: 400;
    font-size: 12px;
  }
}